@font-face {
    font-family: "Roboto-Regular";
     src: url('../../../assets/Fonts/Roboto-Regular.ttf') format('opentype');
}

@font-face {
     font-family: "Roboto-Bold";
     src: url('../../../assets/Fonts/Roboto-Bold.ttf') format('opentype');
}

@font-face {
    font-family: "Roboto-Medium";
     src: url('../../../assets/Fonts/Roboto-Medium.ttf') format('opentype');
}


@font-face {
    font-family: "Roboto-Thin";
     src: url('../../../assets/Fonts/Roboto-Thin.ttf') format('opentype');
}
.childHeading {
    font-family: "Roboto-Bold";
    font-size: 16px;
    letter-spacing: 3px;
}

.childSubHeading {
    font-family: "Roboto-Regular";
    font-weight: 900;
    letter-spacing: 1px;
}

.childText {
    font-family: "Roboto-Regular";
    font-size: 15px;
    word-spacing: 2px;
    color: #898989;
}
.childDesc{
    font-family: "Roboto-Bold";
    font-size: 16px;
}

.rightText {
    font-family: "Roboto-Thin";
    font-size: 22px;
    font-weight: 700;
    word-spacing: 5px;
    padding: 22px 0px;
    color: #020202;
}
.rightDesc {
    font-family: "Roboto-Thin";
    font-size: 16px;
    word-spacing: 5px;
    padding: 15px 0px;
    color: #8f8f8f;
}


.childSection{
    background: url('.././../../assets//Images/fundairising.jpg');
    background-repeat: no-repeat;
    background-size: contain;
    position: relative;
    margin-top: -150px;
    height: 700px !important;
    filter: brightness(50%);
}

@media (min-width:200px) and (max-width:380px) {
    .childSection {
       background: url('.././../../assets//Images/fundairising.jpg');
        background-repeat: no-repeat;
        background-size: contain;
        position: relative;
        margin-top: -150px;
        height: 130px !important;
        filter: brightness(50%);
    }
}

@media (min-width:380px) and (max-width:400px) {
    .childSection {
       background: url('.././../../assets//Images/fundairising.jpg');
        background-repeat: no-repeat;
        background-size: contain;
        position: relative;
        margin-top: -150px;
        height: 160px !important;
        filter: brightness(50%);
    }
}
@media (min-width:400px) and (max-width:500px) {
    .childSection {
       background: url('.././../../assets//Images/fundairising.jpg');
        background-repeat: no-repeat;
        background-size: contain;
        position: relative;
        margin-top: -150px;
        height: 160px !important;
        filter: brightness(50%);
    }
}
@media (min-width:500px) and (max-width:600px) {
    .childSection {
       background: url('.././../../assets//Images/fundairising.jpg');
        background-repeat: no-repeat;
        background-size: contain;
        position: relative;
        margin-top: -150px;
        height: 230px !important;
        filter: brightness(50%);
    }
}
@media (min-width:600px) and (max-width:768px) {
    .childSection {
       background: url('.././../../assets//Images/fundairising.jpg');
        background-repeat: no-repeat;
        background-size: contain;
        position: relative;
        margin-top: -150px;
        height: 250px !important;
        filter: brightness(50%);
    }
}

@media (min-width:768px) and (max-width:900px) {
    .childSection {
       background: url('.././../../assets//Images/fundairising.jpg');
        background-repeat: no-repeat;
        background-size: contain;
        position: relative;
        margin-top: -150px;
        height: 300px !important;
        filter: brightness(50%);
    }
}
@media (min-width:900px) and (max-width:1050px) {
.childSection {
   background: url('.././../../assets//Images/fundairising.jpg');
    background-repeat: no-repeat;
    background-size: contain;
    position: relative;
    margin-top: -150px;
    height: 400px !important;
    filter: brightness(50%);
}

}