
@font-face {
    font-family: "Roboto-Regular";
     src: url('../../assets/Fonts/Roboto-Regular.ttf') format('opentype');
}

@font-face {
     font-family: "Roboto-Bold";
     src: url('../../assets/Fonts/Roboto-Bold.ttf') format('opentype');
}

@font-face {
    font-family: "Roboto-Medium";
     src: url('../../assets/Fonts/Roboto-Medium.ttf') format('opentype');
}
@font-face {
    font-family: "Roboto-Thin";
     src: url('../../assets/Fonts/Roboto-Thin.ttf') format('opentype');
}
@font-face {
    font-family: "Roboto-Black";
     src: url('../../assets/Fonts/Roboto-Black.ttf') format('opentype');
}

@font-face {
    font-family:"HindSiliguri-Bold" ;
    src: url('../../assets/Fonts/HindSiliguri/HindSiliguri-Bold.ttf');
}

.OurServiceContainer{
    margin: 2px 99px 25px 140px;
    
}

.seviceHeading{
    font-family: "Roboto-Bold";
    font-size: 16px;
    letter-spacing: 3px;
}

.serviceSubHaeding{
    font-family: "Roboto-Regular";
    font-weight: 900;
    letter-spacing: 1px;
}

.ServiceInnerSec{
 padding: 50px;
}

.serviceCustomBtn{
    font-family: "HindSiliguri-Bold";
    background-color: #01323b;
    border: none;
    padding: 10px 15px;
    font-size: 15px;
    color: #796234;
    font-weight: 500;
    border-radius: 5px;  
}
.line{
    width: 47px;
    height: 10px;
    /* padding-left: 10px; */
    position: absolute;
    left: 11%;
}

.ServiceHeading{
    margin: 0px 0px 0px 55px;
}

#cardBody{
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding: 40px;
}

#cardTitle{
    font-family: "HindSiliguri-Bold" !important;
    font-size: 18px;
    justify-content: center;
    display: flex;
    font-weight: 900;
}


#cardText{
    font-family: "Roboto-Thin";
    font-size: 15px;
    font-weight: 900;
    line-height: 23px;
    letter-spacing: 1px;
    color: #8f8f8f;
}
.customBtnCardContainer{
    display: flex;
    justify-content: center;
}
.customSmBtn{
    font-family: "HindSiliguri-Bold";
    background-color: #01323b;
    border: none;
    padding: 15px 20px;
    font-size: 15px;
    color: #796234;
    font-weight: 500;
    border-radius: 5px;
}

.customCard{
    width: 22rem;
    background: white;
    border-radius: 19px;
    border: none;
    box-shadow: 1px 8px 16px 13px #f1f1f1;
}

/* Support Sec Back image */
.background-overlay{
    background-image: url("../../assets//Images/slider1.jpg");
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: center;
    background-size: cover;
}

.supportContainer{
    padding: 120px 140px;
    background: black;
    opacity: .7;
}

.supportHeading{
    font-family: "Roboto-Black" !important;
    font-size: 16px;
    letter-spacing: 5px;
    justify-content: flex-start;
    display: flex;
    font-weight: 900;
    color: white;
}
.supportSubHaeding{
    font-family: "Roboto-Bold" !important;
    color: white;
    font-size: 50px;
    letter-spacing: 5px;
    justify-content: flex-start;
    display: flex;
    font-weight: 900;
}

.supportText{
    font-family: "Roboto-Thin" !important;
    color: white;
    font-size: 14px;
    letter-spacing: 2px;
    justify-content: flex-start;
    display: flex;
    font-weight: 500;
    padding-top: 20px;
}
.mx-full{
    margin: 0px 50px;
}
@media (min-width:200px) and (max-width:600px) {
    
    .supportContainer{
    padding: 70px 15px !important;
    background: black;
    opacity: .7;

    }
}

@media (min-width:200px) and (max-width:1300px) {
    
    .OurServiceContainer{
        margin: 0px !important;
        
    }
}

@media (min-width:200px) and (max-width:1356px)  {
    .customCard {
        width: 16rem;
        background: white;
        border-radius: 19px;
        border: none;
        margin-bottom: 20px;
    }

    #cardBody {
        display: flex;
        justify-content: center;
        flex-direction: column;
        padding: 20px;
    }
    #cardTitle {
        font-family: "Roboto-Regular";
        font-size: 24px !important;
  
    }

    .mx-full{
        margin:auto !important;
    }
}