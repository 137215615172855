
@font-face {
    font-family: "Roboto-Regular";
     src: url('../../assets/Fonts/Roboto-Regular.ttf') format('opentype');
}

@font-face {
     font-family: "Roboto-Bold";
     src: url('../../assets/Fonts/Roboto-Bold.ttf') format('opentype');
}

@font-face {
    font-family: "Roboto-Medium";
     src: url('../../assets/Fonts/Roboto-Medium.ttf') format('opentype');
}

*{
    font-family: "Roboto-Regular";
}

.contactHeading{
    font-family: "Roboto-Regular";
    font-weight: 900;
    letter-spacing: 1px;
    font-size: 20px;
}
.contactHead{
    font-family: "Roboto-Regular";
    font-weight: 900;
    letter-spacing: 1px;
    font-size: 30px;
}
.contactTitle{
    font-family: "Roboto-Bold";
    font-size: 16px;
    letter-spacing: 3px;
    
}

.contacticonContainer{
    padding: 0px !important;
    display: flex;
    justify-content: space-evenly;
    flex-direction: row;
    margin: 0px 6px 0px -30px;
    width: 30%;
}
.contactInfo{
    font-family: "Roboto-Regular";
    font-size: 15px;
    padding: 5px 0px;
    color: #c3c3c3;
    word-spacing: 2px;
}


#sIcon{
color: rgb(3, 3, 3);
}
.contactText{
    font-family: "Roboto-Regular";
    font-size: 15px;
    word-spacing: 2px;
    color: #c3c3c3;
}
input{
    font-family: "Roboto-Regular";
}
.contactContainer{
   background: url('../../assets/Images/contact/contact.jpg');
    background-repeat: no-repeat;
     background-position: center;
    background-size: cover;
    position: relative;
    margin-top: -150px;
    height: 700px;
    filter: brightness(50%);
}



.contactFormContainer{
    background: white;
    border-radius: 8px;
    border: none;
    box-shadow: 1px 8px 16px 13px #f1f1f1;
    padding: 30px;
}
.fomrTopHeading{
    padding: 0px 0px 0px 50px;
}
#nameInput{
    font-size: 16px;
    padding: 16px 15px !important;
}
#emailInput{
    font-size: 16px;
    padding: 16px 15px !important;
}

#phoneInput{
    font-size: 16px;
    padding: 16px 15px !important;
}

#textAreaField{
    font-size: 16px;
    padding: 0px 15px !important;
    margin: 0px 0px 0px 33px;
    width: 88%;
}

.contactCustombtn{
    background-color: #01323b;
    border: none;
    padding: 10px 20px;
    font-size: 18px;
    color: #796234;
    font-weight: 500;
    border-radius: 5px;
    margin-bottom: 20px;
}

.option{
    color: gray !important;
}


/* Media Query Form Section */
@media (min-width:200px) and (max-width:400px) {
    #textAreaField {
        font-size: 16px;
        padding: 0px 15px !important;
        margin: 20px 0px 0px 33px;
        width: 76%;
    
    }
    .contactFormContainer {
        background: white;
        border-radius: 8px;
        border: none;
        box-shadow: 1px 8px 16px 13px #f1f1f1;
        padding: 10px;
    }

    .contacticonContainer {
        padding: 0px !important;
        display: flex;
        justify-content: space-evenly;
        flex-direction: row;
        margin: 0px 6px 0px 0px;
        width: 100%;
    }
    .contactCustombtn {
        background-color: #01323b;
        border: none;
        padding: 10px 20px;
        font-size: 15px;
        color: #796234;
        font-weight: 500;
        border-radius: 5px;
        margin-bottom: 20px;
        width: 100%;
    }

    .customCard {
        width: 18rem;
        background: white;
        border-radius: 19px;
        border: none;
        box-shadow: 1px 8px 16px 13px #f1f1f1;
    }
}
@media (min-width:400px) and (max-width:514px) {
    #textAreaField{
        font-size: 16px;
        padding: 0px 15px !important;
        margin: 20px 0px 0px 32px;
        width: 85%;
    }
    .contactFormContainer {
        background: white;
        border-radius: 8px;
        border: none;
        box-shadow: 1px 8px 16px 13px #f1f1f1;
        padding: 25px;
    }


    .customCard {
        width: 18rem;
        background: white;
        border-radius: 19px;
        border: none;
        box-shadow: 1px 8px 16px 13px #f1f1f1;
    }
}
@media (min-width:514px) and (max-width:767px) {
    #textAreaField{
        font-size: 16px;
        padding: 0px 15px !important;
        margin: 20px 0px 0px 30px;
        width: 85%;
    }
}
@media (min-width:767px) and (max-width:992px) {
    #textAreaField{
        font-size: 16px;
        padding: 0px 15px !important;
        margin: 20px 0px 0px 33px;
        width: 89%;
    }
}

@media (min-width:992px) and (max-width:1200px) {
    #textAreaField{
        font-size: 16px;
        padding: 15px 12px !important;
        margin: 0px 0px 0px 35px;
        width: 80%;
    }
}
@media (min-width:1200px) and (max-width:1400px) {
    #textAreaField{
        font-size: 16px;
        padding: 0px 15px !important;
        margin: 20px 0px 0px 35px;
        width: 85%;
    }

}


/* Media Query Top Section */

@media (min-width:200px) and (max-width:380px) {
    .contactContainer {
       background: url('../../assets/Images/contact/contact.jpg');
        background-repeat: no-repeat;
         background-position: center;
    background-size: cover;
        position: relative;
        margin-top: -150px;
        height: 130px !important;
        filter: brightness(50%);
    }
}

@media (min-width:380px) and (max-width:400px) {
    .contactContainer {
       background: url('../../assets/Images/contact/contact.jpg');
        background-repeat: no-repeat;
         background-position: center;
    background-size: cover;
        position: relative;
        margin-top: -150px;
        height: 160px !important;
        filter: brightness(50%);
    }
}
@media (min-width:400px) and (max-width:500px) {
    .contactContainer {
       background: url('../../assets/Images/contact/contact.jpg');
        background-repeat: no-repeat;
         background-position: center;
    background-size: cover;
        position: relative;
        margin-top: -150px;
        height: 160px !important;
        filter: brightness(50%);
    }
}
@media (min-width:500px) and (max-width:600px) {
    .contactContainer {
       background: url('../../assets/Images/contact/contact.jpg');
        background-repeat: no-repeat;
         background-position: center;
    background-size: cover;
        position: relative;
        margin-top: -150px;
        height: 230px !important;
        filter: brightness(50%);
    }
}
@media (min-width:600px) and (max-width:768px) {
    .contactContainer {
       background: url('../../assets/Images/contact/contact.jpg');
        background-repeat: no-repeat;
         background-position: center;
    background-size: cover;
        position: relative;
        margin-top: -150px;
        height: 280px !important;
        filter: brightness(50%);
    }
}

@media (min-width:768px) and (max-width:900px) {
    .contactContainer {
       background: url('../../assets/Images/contact/contact.jpg');
        background-repeat: no-repeat;
         background-position: center;
    background-size: cover;
        position: relative;
        margin-top: -150px;
        height: 300px !important;
        filter: brightness(50%);
    }
}
@media (min-width:900px) and (max-width:1050px) {
.contactContainer {
   background: url('../../assets/Images/contact/contact.jpg');
    background-repeat: no-repeat;
     background-position: center;
    background-size: cover;
    position: relative;
    margin-top: -150px;
    height: 400px !important;
    filter: brightness(50%);
}

}