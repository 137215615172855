
@font-face {
    font-family: "Roboto-Regular";
     src: url('../../assets/Fonts/Roboto-Regular.ttf') format('opentype');
}

@font-face {
     font-family: "Roboto-Bold";
     src: url('../../assets/Fonts/Roboto-Bold.ttf') format('opentype');
}

@font-face {
    font-family: "Roboto-Medium";
     src: url('../../assets/Fonts/Roboto-Medium.ttf') format('opentype');
}
@font-face {
    font-family: "Roboto-Thin";
     src: url('../../assets/Fonts/Roboto-Thin.ttf') format('opentype');
}

@font-face {
    font-family: "HindSiliguri-Bold";
     src: url('../../assets/Fonts/HindSiliguri/HindSiliguri-Bold.ttf') format('opentype');
}
.SliderContainer{
    position: relative;
    margin-top: -140px;
    width: 100%;
    height: 700px;
    z-index: 2;
    background-color: #000000;
    overflow: hidden;
    opacity: 5.7;
}
.overlay {
    background-color: #080d15;
    opacity: .7;
    height: 100%;

}
#sliderImg{
    width: 100%;
    background-color: #080d15;
    opacity: .7;
    height: 100%;
    /* height: 100vh !important; */
} 

.caption{
     position: relative;
    margin: 0;
    bottom: 99px;
    color: white !important; 
     /* background-color: #000000;  */
     /* left: 50%;
    bottom: 0%; */
    /* transform: translate(-50%, -50%);  */
    opacity: 1 !important;
    background-color: none;
    z-index: 999;
}

.customBtn2{
    font-family: "HindSiliguri-Bold";
    background-color: #01323b;
    border: none;
    padding: 10px 22px;
    font-size: 18px;
    color: #796234;
    font-weight: 500;
    border-radius: 5px;
}

#TopSliderHeading{
    font-size: 16px;
    font-family: "HindSiliguri-Bold";
    letter-spacing: 4px;
}

#TopSliderdisc{
    color: white !important;
    font-family: "Roboto-Medium";
    font-size: 39px;
    font-weight: 500;
    text-transform: capitalize;
}
#TopSliderInner{
    color: #796234 !important;
    font-family: "Roboto-Bold" !important;
}
#TopSlidertext{
    color: white !important;
    font-family: "Roboto-Thin";
    font-weight: 400;
    font-size: 16px;
    text-transform: lowercase;
    padding: 20px 100px;
}

@media (min-width:300px) and (max-width:495px){
    .SliderContainer {
        position: relative;
        margin-top: -140px;
        width: 100%;
        height: 100%;
        z-index: 2;
        background-color: #000000;
        overflow: hidden;
        opacity: 5.7;
    }
    #TopSlidertext {
        color: white !important;
        font-family: "Roboto-Thin";
        font-weight: 400;
        font-size: 8px;
        text-transform: lowercase;
        padding: 0px;
    }


    #TopSliderdisc {
        color: white !important;
        font-family: "Roboto-Medium";
        font-size: 8px;
        font-weight: 500;
        text-transform: capitalize;
    }
    .cardContainer {
        position: relative;
        z-index: 999;
        margin-top: 25px !important;
    }

    .caption {
        position: relative;
        margin: 0;
        bottom: 0px;
        color: white !important;
        /* background-color: #000000; */
        /* transform: translate(-50%, -50%); */
        opacity: 1 !important;
        background-color: none;
        z-index: 999;
    }

    #TopSliderHeading {
        font-size: 8px;
        font-family: "Roboto-Bold";
        letter-spacing: 4px;
    }

    .customBtn2 {
        background-color: #01323b;
        border: none;
        padding: 8px 13px;
        font-size: 10px;
        color: #796234;
        font-weight: 500;
        border-radius: 5px;
    }

   
}
@media (min-width:495px) and (max-width:600px) {
    
    #TopSlidertext {
        color: white !important;
        font-family: "Roboto-Thin";
        font-weight: 400;
        font-size: 12px;
        text-transform: lowercase;
        padding: 0px 5px;
    }

    #TopSliderdisc {
        color: white !important;
        font-family: "Roboto-Medium";
        font-size: 17px;
        font-weight: 500;
        text-transform: capitalize;
    }

    .SliderContainer {
        position: relative;
        margin-top: -140px;
        width: 100%;
        height: 100% !important;
        z-index: 2;
        background-color: #000000;
        overflow: hidden;
        opacity: 5.7;
    }
    .caption {
        position: relative;
        margin: 0;
        bottom: 0px;
        color: white !important;
        opacity: 1 !important;
        background-color: none;
        z-index: 999;
    }
    .vertical{
        display: none !important;
    }

    .cardContainer {
        position: relative;
        z-index: 999;
        margin-top: 30px !important;
    }

}

@media (min-width:600px) and (max-width:768px) {
    

    .SliderContainer {
        position: relative;
        margin-top: -140px;
        width: 100%;
        height: 100%;
        z-index: 2;
        background-color: #000000;
        overflow: hidden;
        opacity: 5.7;
    }


    #TopSliderdisc {
        color: white !important;
        font-family: "Roboto-Medium";
        font-size: 22px;
        font-weight: 500;
        text-transform: capitalize;
    }
    #TopSlidertext {
        color: white !important;
        font-family: "Roboto-Thin";
        font-weight: 400;
        font-size: 14px;
        text-transform: lowercase;
        padding: 0px;
    }
}
@media (min-width:768px) and (max-width:900px) {
    



    .SliderContainer {
        position: relative;
        margin-top: -140px;
        width: 100%;
        height: 100%;
        z-index: 2;
        background-color: #000000;
        overflow: hidden;
        opacity: 5.7;
    }

    .caption {
        position: relative;
        margin: 0;
        bottom: 70px;
        color: white !important;
        /* background-color: #000000; */
        /* transform: translate(-50%, -50%); */
        opacity: 1 !important;
        background-color: none;
        z-index: 999;
    }
}
@media (min-width:900px) and (max-width:1024px) {
    
    .SliderContainer {
        position: relative;
        margin-top: -375px;
        width: 100%;
        height: 100%;
        z-index: 2;
        background-color: #000000;
        overflow: hidden;
        opacity: 5.7;
    }

    
    .caption {
        position: relative;
        margin: 0;
        bottom: 40px;
        color: white !important;
        /* background-color: #000000; */
        /* transform: translate(-50%, -50%); */
        opacity: 1 !important;
        background-color: none;
        z-index: 999;
    }

    .carousel-caption {
        position: absolute;
        right: 15%;
        bottom: 9.25rem;
        left: 15%;
        padding-top: 1.25rem;
        padding-bottom: 1.25rem;
        color: #fff;
        text-align: center;
    }
}

@media (min-width:1024px) and (max-width:1200px) {
    .SliderContainer {
        position: relative;
        margin-top: -140px;
        width: 100%;
        height: 100%;
        z-index: 2;
        background-color: #000000;
        overflow: hidden;
        opacity: 5.7;
    }
}

@media (min-width:1200px) and (max-width:1300px) {
    .SliderContainer {
        position: relative;
        margin-top: -140px;
        width: 100%;
        height: 700px;
        z-index: 2;
        background-color: #000000;
        overflow: hidden;
        opacity: 5.7;
    }
    .caption {
        position: relative;
        margin: 0;
        bottom: 615px!important;
        color: white !important;
        opacity: 1 !important;
        background-color: none;
        z-index: 999;
    }
}


@media (min-width:1300px) and (max-width:1400px) {
    .SliderContainer {
        position: relative;
        margin-top: -140px;
        width: 100%;
        height: 700px !important;
        z-index: 2;
        background-color: #000000;
        overflow: hidden;
        opacity: 5.7;
    }
    .caption {
        position: relative;
        margin: 0;
        bottom: 730px!important;
        color: white !important;
        opacity: 1 !important;
        background-color: none;
        z-index: 999;
    }
}

@media (min-width:1400px) and (max-width:1500px) {
    .SliderContainer {
        position: relative;
        margin-top: -140px;
        width: 100%;
        height: 700px;
        z-index: 2;
        background-color: #000000;
        overflow: hidden;
        opacity: 5.7;
    }
    .caption {
        position: relative;
        margin: 0;
        bottom: 800px!important;
        color: white !important;
        opacity: 1 !important;
        background-color: none;
        z-index: 999;
    }
}

@media (min-width:1500px) and (max-width:1600px) {
    .SliderContainer {
        position: relative;
        margin-top: -140px;
        width: 100%;
        height: 700px;
        z-index: 2;
        background-color: #000000;
        overflow: hidden;
        opacity: 5.7;
    }
    .caption {
        position: relative;
        margin: 0;
        bottom: 850px!important;
        color: white !important;
        opacity: 1 !important;
        background-color: none;
        z-index: 999;
    }
}

@media (min-width:1600px) and (max-width:1700px) {
    .SliderContainer {
        position: relative;
        margin-top: -140px;
        width: 100%;
        height: 700px;
        z-index: 2;
        background-color: #000000;
        overflow: hidden;
        opacity: 5.7;
    }
    .caption {
        position: relative;
        margin: 0;
        bottom: 900px!important;
        color: white !important;
        opacity: 1 !important;
        background-color: none;
        z-index: 999;
    }
}