
@font-face {
    font-family: "Roboto-Regular";
     src: url('../../assets/Fonts/Roboto-Regular.ttf') format('opentype');
}

@font-face {
     font-family: "Roboto-Bold";
     src: url('../../assets/Fonts/Roboto-Bold.ttf') format('opentype');
}

@font-face {
    font-family: "Roboto-Medium";
     src: url('../../assets/Fonts/Roboto-Medium.ttf') format('opentype');
}

*{
    font-family: "Roboto-Regular";
}

.bookHeading{
    font-family: "Roboto-Regular";
    font-weight: 900;
    letter-spacing: 1px;
}
.bookTitle{
    font-family: "Roboto-Bold";
    font-size: 16px;
    letter-spacing: 3px;
}

.bookText{
    font-family: "Roboto-Regular";
    font-size: 15px;
    word-spacing: 2px;
    color: #c3c3c3;
}
input{
    font-family: "Roboto-Regular";
}
.bookNowContainer{
    background: url('../../assets/Images/book-now/hero-booking.jpg');
    background-repeat: no-repeat;
  background-position: center;
    background-size: cover;
    position: relative;
    margin-top: -150px;
    height: 700px;
    filter: brightness(50%);
}



.FormContainer{
    background: white;
    border-radius: 19px;
    border: none;
    box-shadow: 1px 8px 16px 13px #f1f1f1;
    padding: 50px;
}
.fomrTopHeading{
    padding: 0px 0px 0px 50px;
}
#nameInput{
    font-size: 16px;
    padding: 16px 15px !important;
}
#emailInput{
    font-size: 16px;
    padding: 16px 15px !important;
}

#phoneInput{
    font-size: 16px;
    padding: 16px 15px !important;
}

#selectField{
    font-size: 16px;
    padding: 15px 15px !important;
    margin: 20px 0px 0px 45px;
    width: 82%;
}

.custombtn{
    background-color: #01323b;
    border: none;
    padding: 10px 10px;
    font-size: 18px;
    color: #796234;
    font-weight: 500;
    border-radius: 5px;  
    margin-bottom: 20px;
    width: 100%;
}

.option{
    color: gray !important;
}


/* Media Query Form Section */


@media (min-width:200px) and (max-width:380px) {
    .serviceContainer {
       background: url('../../assets/Images/service/service.jpg');
        background-repeat: no-repeat;
      background-position: center;
    background-size: cover;
        position: relative;
        margin-top: -150px;
        height: 130px !important;
    }
}

@media (min-width:200px) and (max-width:400px) {
    #selectField {
        font-size: 16px;
        padding: 15px 15px !important;
        margin: 20px 0px 0px 45px;
        width: 75%;
    }
    .FormContainer {
        background: white;
        border-radius: 19px;
        border: none;
        box-shadow: 1px 8px 16px 13px #f1f1f1;
        padding: 10px;
    }
}
@media (min-width:400px) and (max-width:514px) {
    #selectField{
        font-size: 16px;
        padding: 15px 15px !important;
        margin: 20px 0px 0px 45px;
        width: 76%;
    }
    .FormContainer {
        background: white;
        border-radius: 19px;
        border: none;
        box-shadow: 1px 8px 16px 13px #f1f1f1;
        padding: 25px;
    }
}
@media (min-width:514px) and (max-width:767px) {
    #selectField{
        font-size: 16px;
        padding: 15px 15px !important;
        margin: 20px 0px 0px 45px;
        width: 78%;
    }
}
@media (min-width:767px) and (max-width:992px) {
    #selectField{
        font-size: 16px;
        padding: 15px 15px !important;
        margin: 20px 0px 0px 45px;
        width: 85%;
    }
}

@media (min-width:992px) and (max-width:1200px) {
    #selectField{
        font-size: 16px;
        padding: 15px 15px !important;
        margin: 20px 0px 0px 45px;
        width: 75%;
    }
}
@media (min-width:1200px) and (max-width:1400px) {
    #selectField{
        font-size: 16px;
        padding: 15px 15px !important;
        margin: 20px 0px 0px 45px;
        width: 80%;
    }

}


/* Media Query Top Section */

@media (min-width:200px) and (max-width:380px) {
    .bookNowContainer {
        background: url('../../assets/Images/book-now/hero-booking.jpg');
        background-repeat: no-repeat;
      background-position: center;
    background-size: cover;
        position: relative;
        margin-top: -150px;
        height: 130px !important;
        filter: brightness(50%);
    }
}

@media (min-width:380px) and (max-width:400px) {
    .bookNowContainer {
        background: url('../../assets/Images/book-now/hero-booking.jpg');
        background-repeat: no-repeat;
      background-position: center;
    background-size: cover;
        position: relative;
        margin-top: -150px;
        height: 160px !important;
        filter: brightness(50%);
    }
}
@media (min-width:400px) and (max-width:500px) {
    .bookNowContainer {
        background: url('../../assets/Images/book-now/hero-booking.jpg');
        background-repeat: no-repeat;
      background-position: center;
    background-size: cover;
        position: relative;
        margin-top: -150px;
        height: 160px !important;
        filter: brightness(50%);
    }
}
@media (min-width:500px) and (max-width:600px) {
    .bookNowContainer {
        background: url('../../assets/Images/book-now/hero-booking.jpg');
        background-repeat: no-repeat;
      background-position: center;
    background-size: cover;
        position: relative;
        margin-top: -150px;
        height: 230px !important;
        filter: brightness(50%);
    }
}
@media (min-width:600px) and (max-width:768px) {
    .bookNowContainer {
        background: url('../../assets/Images/book-now/hero-booking.jpg');
        background-repeat: no-repeat;
      background-position: center;
    background-size: cover;
        position: relative;
        margin-top: -150px;
        height: 280px !important;
        filter: brightness(50%);        
    }
}

@media (min-width:768px) and (max-width:900px) {
    .bookNowContainer {
        background: url('../../assets/Images/book-now/hero-booking.jpg');
        background-repeat: no-repeat;
      background-position: center;
    background-size: cover;
        position: relative;
        margin-top: -150px;
        height: 300px !important;
        filter: brightness(50%);
    }
}
@media (min-width:900px) and (max-width:1050px) {
.bookNowContainer {
    background: url('../../assets/Images/book-now/hero-booking.jpg');
    background-repeat: no-repeat;
  background-position: center;
    background-size: cover;
    position: relative;
    margin-top: -150px;
    height: 400px !important;
    filter: brightness(50%);
}

}