
@font-face {
    font-family: "Roboto-Regular";
     src: url('../../assets/Fonts/Roboto-Regular.ttf') format('opentype');
}

@font-face {
     font-family: "Roboto-Bold";
     src: url('../../assets/Fonts/Roboto-Bold.ttf') format('opentype');
}

@font-face {
    font-family: "Roboto-Medium";
     src: url('../../assets/Fonts/Roboto-Medium.ttf') format('opentype');
}
@font-face {
    font-family: "HindSiliguri-Bold";
     src: url('../../assets/Fonts/HindSiliguri/HindSiliguri-Bold.ttf') format('opentype');
}

*{
    font-family: "Roboto-Regular";
}
.AboutContainer{
    position: relative;
    margin: 0px 110px 0px 110px;
}
.about-Heading{
    font-family: "Roboto-Bold";
    font-size: 16px;
    letter-spacing: 3px;
}
.aboutText{
    font-family: "Roboto-Regular";
    font-size: 15px;
    word-spacing: 2px;
    color: #c3c3c3;
}

.aboutSubHeading{
    font-family: "Roboto-Regular";
    font-weight: 900;
    letter-spacing: 1px;
}
.aboutcustomBtn{
    font-family: "HindSiliguri-Bold";
    background-color: #01323b;
    border: none;
    padding: 10px 10px;
    font-size: 18px;
    color: #796234;
    font-weight: 500;
    border-radius: 5px;  
    margin-bottom: 20px;
}
.AboutInnerSec{
    padding: 50px 0px !important; 
}

@media (max-width:1400px) {
    .AboutContainer{
        position: relative;
        margin: 0px;
    }
   
    
}

@media (max-width:900px) {
   
    .AboutInnerSec{
        padding: 0px;
    }
    
}