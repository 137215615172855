@font-face {
    font-family: "Roboto-Regular";
     src: url('../../assets/Fonts/Roboto-Regular.ttf') format('opentype');
}

@font-face {
     font-family: "Roboto-Bold";
     src: url('../../assets/Fonts/Roboto-Bold.ttf') format('opentype');
}

@font-face {
    font-family: "Roboto-Medium";
     src: url('../../assets/Fonts/Roboto-Medium.ttf') format('opentype');
}

*{
    font-family: "Roboto-Regular";
}
.AboutContainer{
    position: relative;
    margin: 0px 110px 0px 110px;
}
.aboutHeading{
    font-family: "Roboto-Bold";
    font-size: 16px;
    letter-spacing: 3px;
}
.about-Text{
    font-family: "Roboto-Regular";
    font-size: 15px;
    word-spacing: 2px;
    color: #c3c3c3;
}

.SubHeading{
    font-family: "Roboto-Regular";
    font-weight: 900;
    letter-spacing: 1px;
}
.customBtn{
    background-color: #01323b;
    border: none;
    padding: 10px 10px;
    font-size: 18px;
    color: #796234;
    font-weight: 500;
    border-radius: 5px;  
    margin-bottom: 20px;
}
.AboutInnerSec{
    padding: 50px 0px !important; 
}

@media (max-width:1400px) {
    .AboutContainer{
        position: relative;
        margin: 0px;
    }
   
    
}

@media (max-width:900px) {
   
    .AboutInnerSec{
        padding: 0px;
    }
    
}


.aboutSection{
    background: url('../../assets/Images/about/about.jpg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    position: relative;
    margin-top: -150px;
    height: 700px !important;
    filter: brightness(50%);
}

@media (min-width:200px) and (max-width:380px) {
    .aboutSection {
        background: url('../../assets/Images/about/about.jpg');
        background-repeat: no-repeat;
        background-position: center;
    background-size: cover;
        position: relative;
        margin-top: -150px;
        height: 130px !important;
        filter: brightness(50%);
    }
}

@media (min-width:380px) and (max-width:400px) {
    .aboutSection {
        background: url('../../assets/Images/about/about.jpg');
        background-repeat: no-repeat;
        background-position: center;
    background-size: cover;
        position: relative;
        margin-top: -150px;
        height: 160px !important;
        filter: brightness(50%);
    }
}
@media (min-width:400px) and (max-width:500px) {
    .aboutSection {
        background: url('../../assets/Images/about/about.jpg');
        background-repeat: no-repeat;
        background-position: center;
    background-size: cover;
        position: relative;
        margin-top: -150px;
        height: 160px !important;
        filter: brightness(50%);
    }
}
@media (min-width:500px) and (max-width:600px) {
    .aboutSection {
        background: url('../../assets/Images/about/about.jpg');
        background-repeat: no-repeat;
        background-position: center;
    background-size: cover;
        position: relative;
        margin-top: -150px;
        height: 230px !important;
        filter: brightness(50%);
    }
}
@media (min-width:600px) and (max-width:768px) {
    .aboutSection {
        background: url('../../assets/Images/about/about.jpg');
        background-repeat: no-repeat;
        background-position: center;
    background-size: cover;
        position: relative;
        margin-top: -150px;
        height: 250px !important;
        filter: brightness(50%);
    }
}

@media (min-width:768px) and (max-width:900px) {
    .aboutSection {
        background: url('../../assets/Images/about/about.jpg');
        background-repeat: no-repeat;
        background-position: center;
    background-size: cover;
        position: relative;
        margin-top: -150px;
        height: 300px !important;
        filter: brightness(50%);
    }
}
@media (min-width:900px) and (max-width:1050px) {
.aboutSection {
    background: url('../../assets/Images/about/about.jpg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    position: relative;
    margin-top: -150px;
    height: 400px !important;
    filter: brightness(50%);
}

}