
@font-face {
    font-family: "Roboto-Regular";
     src: url('../../assets/Fonts/Roboto-Regular.ttf') format('opentype');
}

@font-face {
     font-family: "Roboto-Bold";
     src: url('../../assets/Fonts/Roboto-Bold.ttf') format('opentype');
}

@font-face {
    font-family: "Roboto-Medium";
     src: url('../../assets/Fonts/Roboto-Medium.ttf') format('opentype');
}


@font-face {
    font-family: "HindSiliguri-Bold";
     src: url('../../assets/Fonts/HindSiliguri/HindSiliguri-Bold.ttf') format('opentype');
}


*{
    font-family: "Roboto-Regular";
}
.dHeading{
    font-family: "Roboto-Bold";
    font-size: 16px;
    letter-spacing: 5px;
}

.dSubHeading{
    font-family: "Roboto-Regular";
    font-weight: 900;
    letter-spacing: 1px;
}
.dText{
    font-family: "Roboto-Bold";
    font-size: 15px;
    word-spacing: 2px;
    color: #c3c3c3;
    
}
.number{
    font-size: 35px;
    text-align: center;
    color: #01323b;;
}
.customBtnDonation{
    font-family: "HindSiliguri-Bold";
    background-color: #01323b;
    border: none;
    padding: 10px 20px;
    font-size: 14px;
    color: #796234;
    font-weight: 500;
    border-radius: 5px;
    margin-bottom: 20px;
}

.videoSection{
    margin: 3px 120px 0px 140px;
}

#smallCard{
    margin: 0px 0px 83px 10px;
    border: none;
    background: #EBF0EE;
   /* box-shadow: 1px 8px 16px 13px #f1f1f1; */
}
#smallCard{
background: url('../../assets/Images/pattern1.png');
background-repeat: no-repeat;
}
#cardText{
    font-family: "Roboto-Regular";
    font-size: 16px;
    font-weight: 400;
    color: black;
    display: flex;
    justify-content: center;
}
#cardTitle{
    font-family: "Roboto-Regular";
    font-size: 40px;
    display: flex;
    justify-content: center;
    font-weight: 400 !important;
    color:#01323b;
}


@media (max-width:1400px) {
    .DonationContainer{
        position: relative;
        margin: 0px;
    }
    .videoSection{
        margin: 0px !important;
    }
    
}


@media (max-width:900px) {
   
    .DonationInnerSec{
        padding: 0px;
    }
    
}
