
@font-face {
    font-family: "Roboto-Regular";
     src: url('../../assets/Fonts/Roboto-Regular.ttf') format('opentype');
}

@font-face {
     font-family: "Roboto-Bold";
     src: url('../../assets/Fonts/Roboto-Bold.ttf') format('opentype');
}

@font-face {
    font-family: "Roboto-Medium";
     src: url('../../assets/Fonts/Roboto-Medium.ttf') format('opentype');
}
@font-face {
    font-family: "Roboto-Thin";
     src: url('../../assets/Fonts/Roboto-Thin.ttf') format('opentype');
}
@font-face {
    font-family: "Roboto-Black";
     src: url('../../assets/Fonts/Roboto-Black.ttf') format('opentype');
}

.OurServiceContainer{
    margin: 2px 99px 25px 140px;
    
}

.seviceHeading{
    font-family: "Roboto-Bold";
    font-size: 16px;
    letter-spacing: 3px;
}

.serviceSubHaeding{
    font-family: "Roboto-Regular";
    font-weight: 900;
    letter-spacing: 1px;
}

.ServiceInnerSec{
 padding: 50px;
}

.serviceCustomBtn{
    background-color: #01323b;
    border: none;
    padding: 10px 15px;
    font-size: 15px;
    color: #796234;
    font-weight: 500;
    border-radius: 5px;  
}
.line{
    width: 47px;
    height: 10px;
    /* padding-left: 10px; */
    position: absolute;
    left: 11%;
}

.ServiceHeading{
    margin: 0px 0px 0px 55px;
}

#cardBody{
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding: 40px;
}

#cardTitle{
    font-family: "Roboto-Bold" !important;
    font-size: 18px;
    justify-content: center;
    display: flex;
    font-weight: 900;
}


#cardText{
    font-family: "Roboto-Thin";
    font-size: 15px;
    font-weight: 900;
    line-height: 23px;
    letter-spacing: 1px;
    color: #8f8f8f;
}
.customBtnCardContainer{
    display: flex;
    justify-content: center;
}
.customSmBtn{
    background-color: #01323b;
    border: none;
    padding: 15px 20px;
    font-size: 15px;
    color: #796234;
    font-weight: 500;
    border-radius: 5px;
}

.customCard{
    width: 22rem;
    background: white;
    border-radius: 19px;
    border: none;
    box-shadow: 1px 8px 16px 13px #f1f1f1;
}
.serviceContainer{
    background: url('../../assets/Images/service/service.jpg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    position: relative;
    margin-top: -150px;
    height: 700px;
    filter: brightness(50%);
    
}


@media (min-width:200px) and (max-width:380px) {
    .serviceContainer {
       background: url('../../assets/Images/service/service.jpg');
        background-repeat: no-repeat;
        background-position: center;
    background-size: cover;
        position: relative;
        margin-top: -150px;
        height: 130px !important;
        filter: brightness(50%);
    }

    .customCard {
        width: 18rem !important;
    }
}

@media (min-width:380px) and (max-width:400px) {
    .serviceContainer {
       background: url('../../assets/Images/service/service.jpg');
        background-repeat: no-repeat;
        background-position: center;
    background-size: cover;
        position: relative;
        margin-top: -150px;
        height: 160px !important;
        filter: brightness(50%);
    }

    .customCard {
        width: 18rem !important;
    }
}
@media (min-width:400px) and (max-width:500px) {
    .serviceContainer {
       background: url('../../assets/Images/service/service.jpg');
        background-repeat: no-repeat;
        background-position: center;
    background-size: cover;
        position: relative;
        margin-top: -150px;
        height: 160px !important;
        filter: brightness(50%);
    }

    .customCard {
        width: 18rem !important;
    }
}
@media (min-width:500px) and (max-width:600px) {
    .serviceContainer {
       background: url('../../assets/Images/service/service.jpg');
        background-repeat: no-repeat;
        background-position: center;
    background-size: cover;
        position: relative;
        margin-top: -150px;
        height: 230px !important;
        filter: brightness(50%);
    }

    .customCard {
        width: 18rem !important;
    }
}
@media (min-width:600px) and (max-width:768px) {
    .serviceContainer {
       background: url('../../assets/Images/service/service.jpg');
        background-repeat: no-repeat;
        background-position: center;
    background-size: cover;
        position: relative;
        margin-top: -150px;
        height: 250px !important;
        filter: brightness(50%);
    }

    .customCard {
        width: 18rem !important;
    }
}

@media (min-width:768px) and (max-width:900px) {
    .serviceContainer {
       background: url('../../assets/Images/service/service.jpg');
        background-repeat: no-repeat;
        background-position: center;
    background-size: cover;
        position: relative;
        margin-top: -150px;
        height: 300px !important;
        filter: brightness(50%);
    }
}
@media (min-width:900px) and (max-width:1050px) {
.serviceContainer {
   background: url('../../assets/Images/service/service.jpg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    position: relative;
    margin-top: -150px;
    height: 400px !important;
    filter: brightness(50%);
}



}


@media (min-width: 200px) and (max-width: 1400px){
.mx-full {
    margin: auto !important;
    display: flex;
    justify-content: center;
}

}