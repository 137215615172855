

@font-face {
    font-family: "Roboto-Regular";
     src: url('../../assets/Fonts/Roboto-Regular.ttf') format('opentype');
}

@font-face {
     font-family: "Roboto-Bold";
     src: url('../../assets/Fonts/Roboto-Bold.ttf') format('opentype');
}

@font-face {
    font-family: "Roboto-Medium";
     src: url('../../assets/Fonts/Roboto-Medium.ttf') format('opentype');
}
@font-face {
    font-family: "HindSiliguri-Bold";
     src: url('../../assets/Fonts/HindSiliguri/HindSiliguri-Bold.ttf') format('opentype');
}

*{
    font-family: "Roboto-Regular";

}
.bg{
    background-color: #222222;
}
.rightSideSec{
    padding: 70px 0px 40px 0px;
}
.leftSideSec{
    padding: 70px 0px 40px 30px;
}
.footerHeading{
    font-family: "Roboto-Bold";
    font-size: 24px;
    font-weight: 800;
    color: white;
}

.footerText{
    font-family: "Roboto-Regular";
    font-size: 14px;
    color: #FFFFFF99;
}
.footerCustomBtn{
    font-family: "HindSiliguri-Bold" !important;
    font-family: "Roboto-Bold";
    background-color: #01323b;
    border: none;
    padding: 13px 10px;
    font-size: 16px;
    color: #796234;
    font-weight: 500;
    border-radius: 5px;
    width: 100%;
}

#footerInput{
       padding: 13px 10px;
    font-size: 18px;
    color: #c9c9c9;
    border: none;
}
.footerContactUs{
    font-family: "Roboto-Bold";
    font-size: 24px;
    font-weight: 800;
    color: white;
}
.footerPhone{
    font-family: "Roboto-Regular";
    font-size: 18px;
    color: #FFFFFF99;
    margin: 39px 0px 4px 0px;
}

.footerEmail{
    font-family: "Roboto-Regular";
    font-size: 18px;
    color: #FFFFFF99;
    margin: 0px 0px 4px 0px;
}

.footerLogoContainer{
    width: 40%;
    margin: auto;

}

.f-heading{
    font-family: "Roboto-Regular";
    color: white;
    font-size: 20px;
    text-align: center;
}
.f-text{
    font-family: "Roboto-Regular";
    color: #c9c9c9;
    font-size: 16px;
    text-align: start;
}
.color-gold{
    color: #796234;
    font-family: "Roboto-Bold";
    font-size: 16px;
}

.footerLinks{
    margin: 116px 0px 0px 20px;
}
.qMenu{
    font-family: "Roboto-Bold";
    color: white;
    font-size: 22px;
    margin-bottom: 10px;
}

#link{
    font-family: "Roboto-Regular";
    font-size: 16px;
    text-decoration: none !important;
    color: #c9c9c9;
    cursor: pointer;

}
#linkText{
    font-family: "Roboto-Regular";
    font-size: 16px;
    text-decoration: none !important;
    color: #c9c9c9;
    cursor: auto;
    padding: 0px !important;
}
.linksContainer{
    text-decoration: none !important;
}
li{
    text-decoration: none !important;
    list-style: none !important;
}

.serviceHeading{
    font-family: "Roboto-Bold";
    font-size: 24px;
    font-weight: 800;
    color: white;
}
.followHeading{
    font-family: "Roboto-Bold";
    font-size: 24px;
    font-weight: 800;
    color: white;
}
.iconContainer{
    padding: 0px !important;
    display: flex;
    justify-content: space-evenly;
    flex-direction: row;
    margin: 0px 6px 0px -30px;
}

#socialIcon{
color: white;
}

.copyRightText{
    font-family: "Roboto-Medium";
    font-size: 12px;

    color: #c9c9c9;
    margin: 0px;
    padding: 28px 10px;

}


/* Media Query */

@media (min-width:200px) and (max-width:768px) {
    .footerLinks {
        margin: 0px ;
    }
   
}