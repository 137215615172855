@font-face {
    font-family: "Roboto-Regular";
     src: url('../../assets/Fonts/Roboto-Regular.ttf') format('opentype')
}
@font-face {
    font-family: "Roboto-Bold";
     src: url('../../assets/Fonts/Roboto-Bold.ttf') format('opentype')
}
*{
    font-family: "Roboto-Regular";
}
.cardColor{
    font-family: "Roboto-Regular";
   background-color: #01323B;
   border-radius: 5px;
   text-align: center !important;
}

.icon{
    font-size: 40px;
    color: white;
}
.cardPara{
    color: white;
    font-size: 13px;
    padding: 20px;
}
.cardTittle{
    display: flex;
    justify-content: center;
    color: #796234;
    font-weight: 900;
    font-size: 16px;
    font-family: 'Roboto-Bold';
}

.card{
    border-radius: 22px;
}

.cardContainer{
    position: relative;
    z-index: 999;
    margin-top: -60px;
 
 

}



@media (min-width: 200px) and (max-width: 1400px){
    .mx-full {
        margin: auto !important;
        display: flex;
        justify-content: center;
    }
    
    }