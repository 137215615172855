
@font-face {
    font-family: "Roboto-Regular";
     src: url('../../assets/Fonts/Roboto-Regular.ttf') format('opentype');
}

@font-face {
     font-family: "Roboto-Bold";
     src: url('../../assets/Fonts/Roboto-Bold.ttf') format('opentype');
}

@font-face {
    font-family: "Roboto-Medium";
     src: url('../../assets/Fonts/Roboto-Medium.ttf') format('opentype');
}


@font-face {
    font-family: "HindSiliguri-Bold";
     src: url('../../assets/Fonts/HindSiliguri/HindSiliguri-Bold.ttf') format('opentype');
}
@font-face {
    font-family: "HindSiliguri-Regular";
     src: url('../../assets/Fonts/HindSiliguri/HindSiliguri-Regular.ttf') format('opentype');
}
*{
    font-family: "HindSiliguri-Regular";
}
#navbar{
    position: relative;
    background: transparent;
    z-index: 999 !important;
    border-bottom: 1px solid white;
}
.navLogo{
    width: 118px;
    margin: 0px 0px 0px 150px;
}
.dropdown-toggle::after {
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent;
    color: #fff;
}
.dropdown-toggle:hover {
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent;
    color: #796234 !important;
}

.navbar-toggler{
    color: #01323b !important;
    }
#Link{
    text-decoration: none !important;
    color: #01323b !important;
    padding: 20px;
    font-family: "HindSiliguri-Bold";
}
#customBtnLink{
    text-decoration: none !important;
    color: #796234;
    font-family: "HindSiliguri-Bold";
}
#customBtn:hover{
    text-decoration: none !important;
    color: #01323b;
}
#customBtnLink:hover{
    text-decoration: none !important;
    color: #01323b;
}
.dropdown{
    background-color: transparent !important;
    border: none !important;
    text-decoration: none;
    text-decoration: none !important;
    color: #01323b !important; 
    margin-top: 1px;
}
.customBtn{
    background-color: #01323b;
    border: none;
    padding: 10px 10px;
    font-size: 18px;
    color: #796234;
    font-weight: 500;
    border-radius: 5px;  
    font-family: "HindSiliguri-Bold";
}


  .customBtn:hover {
    margin-bottom: 10px;
    transition-delay: 1s;
    transition: 1s;
    background-color: #796234;
    color:#FFF !important;
    animation: pulse 1s infinite;
    transition: .3s;
  }
  @keyframes pulse {
    0% {
      transform: scale(1);
    }
    70% {
      transform: scale(.9);
    }
      100% {
      transform: scale(1);
    }
  }
.homeNav{
    margin: 0px 10px 0px 170px;
}
.homeLink{
    font-family: "Roboto-Medium";
    color: white;
    font-size: 18px;
    font-weight: 500;
    text-decoration: none;
}
.homeLink:hover{
    font-family: "Roboto-Medium";
    color: #796234;
    font-size: 18px;
    font-weight: 500;
    text-decoration: none;
}
.homeLink:active{
     font-family: "Roboto-Medium";
    color: #01323b !important;
    font-size: 18px;
    font-weight: 500;
    text-decoration: none;
}
.homeLink:focus{ 
    font-family: "Roboto-Medium";
    color: #01323b !important;
   font-size: 18px;
   font-weight: 500;
   text-decoration: none;
}


.aboutNav{
    margin: 0px 10px 0px 20px;
}
.aboutLink{
     font-family: "Roboto-Medium";
    color: white;
    font-size: 18px;
    font-weight: 500;
    text-decoration: none !important;
}
.aboutLink:hover{
     font-family: "Roboto-Medium";
    color: #796234;
    font-size: 18px;
    font-weight: 500;
    text-decoration: none !important;
}
.aboutLink:active{
     font-family: "Roboto-Medium";
    color: #01323b !important;
    font-size: 18px;
    font-weight: 500;
    text-decoration: none !important;
}
.aboutLink:focus{
    font-family: "Roboto-Medium";
   color: #01323b !important;
   font-size: 18px;
   font-weight: 500;
   text-decoration: none !important;
}

.serviceNav{
    margin: 0px 10px 0px 20px;
}
.serviceLink{
     font-family: "Roboto-Medium";
    color: white;
    font-size: 18px;
    font-weight: 500;
    text-decoration: none;
}
.serviceLink:hover{
     font-family: "Roboto-Medium";
    color: #796234;
    font-size: 18px;
    font-weight: 500;
    text-decoration: none;
}
.serviceLink:active{
     font-family: "Roboto-Medium";
    color: #01323b !important;
    font-size: 18px;
    font-weight: 500;
    text-decoration: none;
}
.serviceLink:focus{
    font-family: "Roboto-Medium";
   color: #01323b !important;
   font-size: 18px;
   font-weight: 500;
   text-decoration: none;
}

#dropLink{
    font-family: "Roboto-Medium";
    font-size: 18px;
    font-weight: 500;
    color: #ffffff !important;
    text-decoration: none !important;
    margin-top: 5px;
}

#dropLink:hover{
    font-family: "Roboto-Medium";
    color: #796234 !important;
    font-size: 18px;
    font-weight: 500;
    text-decoration: none !important;
}

#dropLink:active{
    font-family: "Roboto-Medium";
   color: #01323b !important;
   font-size: 18px;
   font-weight: 500;
   text-decoration: none !important;
}
#dropLink:focus{
    font-family: "Roboto-Medium";
    color: #01323b !important;
    font-size: 18px;
    font-weight: 500;
    text-decoration: none !important;
}

.contactNav{
    margin: 0px 10px 0px 20px;
 
}


.contactLink{
     font-family: "Roboto-Medium";
    color: white !important;
    font-size: 18px;
    font-weight: 500;
    text-decoration: none;
}
.contactLink:hover{
     font-family: "Roboto-Medium";
    color: #796234 !important;
    font-size: 18px;
    font-weight: 500;
    text-decoration: none;
}
.contactLink:active{
     font-family: "Roboto-Medium";
    color: #01323b !important;
    font-size: 18px;
    font-weight: 500;
    text-decoration: none;
}

.contactLink:focus{
    font-family: "Roboto-Medium";
   color: #01323b !important;
   font-size: 18px;
   font-weight: 500;
   text-decoration: none;
}

/* Line vertical */
  .vertical {
    border-left: 2px solid white;
    height: 134px;
    position: absolute;
    left: 78%;
}

@media (min-width:200px) and (max-width:1200px){
    .navLogo {
        width: 85px;
        margin: 0px;
    }
    
    #navbar {
        border-bottom: none;
    }    

    
    .vertical {
        display: none;
     }
}
@media (min-width:280px) and (max-width:400px){
    #basic-navbar-nav{
      
    background-color: #01323b;
    position: absolute;
    transform: translate(42%, 54%);
    transition: 1s all;
    animation-delay: 2s;
    border-radius: 5px;
    width: 200px;
    z-index: 999;


    }  

    .homeNav {
        margin: 0px;
        align-items: center;
        text-align: center;
    }
    .aboutNav {
        margin: 0px;
        align-items: center;
        text-align: center;
    }
    .serviceNav {
        margin: 0px;
        align-items: center;
        text-align: center;
    }
    .contactNav {
        margin: 0px;
        align-items: center;
        text-align: center;
    }
    .dropdown{
        text-align: center;
    }

    .customBtn {
        background-color: #796234 !important;
        border: none;
        padding: 10px 10px;
        font-size: 15px !important;
        color: #01323b !important;
        font-weight: 500;
        border-radius: 5px;
        margin-bottom: 20px;
    }

    #customBtnLink {
        text-decoration: none !important;
        color: #01323b !important;
    }

    .vertical {
       display: none;
    }
}
@media (min-width:400px) and (max-width:500px){
    #basic-navbar-nav{
        background-color: #01323b;
        position: absolute;
        transform: translate(50%, 75%);
        transition: 1s all;
        animation-delay: 2s;
        border-radius: 5px;
        width: 200px;
    }  

    .homeNav {
        margin: 10px;
        align-items: center;
        text-align: center;
    }
    .aboutNav {
        margin: 10px;
        align-items: center;
        text-align: center;
    }
    .serviceNav {
        margin: 10px;
        align-items: center;
        text-align: center;
    }
    .contactNav {
        margin: 10px;
        align-items: center;
        text-align: center;
    }
    .dropdown{
        text-align: center;
    }

    .customBtn {
        background-color: #796234 !important;
        border: none;
        padding: 10px 10px;
        font-size: 18px;
        color: #01323b !important;
        font-weight: 500;
        border-radius: 5px;
        margin-bottom: 20px;
    }

    #customBtnLink {
        text-decoration: none !important;
        color: #01323b !important;
    }

    .vertical {
       display: none;
    }
}


@media (min-width:500px) and (max-width:600px){
    #basic-navbar-nav{
    background-color: #01323b;
    position: absolute;
    transform: translate(13%, 75%);
    transition: 1s all;
    animation-delay: 2s;
    border-radius: 5px;
    width: 400px;
    }  

    .homeNav {
        margin: 0px;
        align-items: center;
        text-align: center;
    }
    .aboutNav {
        margin: 10px;
        align-items: center;
        text-align: center;
    }
    .serviceNav {
        margin: 10px;
        align-items: center;
        text-align: center;
    }
    .contactNav {
        margin: 10px;
        align-items: center;
        text-align: center;
    }
    .dropdown{
        text-align: center;
    }

    .customBtn {
        background-color: #796234 !important;
        border: none;
        padding: 10px 10px;
        font-size: 18px;
        color: #01323b !important;
        font-weight: 500;
        border-radius: 5px;
        margin-bottom: 20px;
    }

    #customBtnLink {
        text-decoration: none !important;
        color: #01323b !important;
    }

    .vertical {
       display: none;
    }
}


@media (min-width:600px) and (max-width:800px){
    #basic-navbar-nav{
    background-color: #01323b;
    position: absolute;
    transform: translate(13%, 75%);
    transition: 1s all;
    animation-delay: 2s;
    border-radius: 5px;
    width: 400px;
    }  

    .homeNav {
        margin: 0px;
        align-items: center;
        text-align: center;
    }
    .aboutNav {
        margin: 10px;
        align-items: center;
        text-align: center;
    }
    .serviceNav {
        margin: 10px;
        align-items: center;
        text-align: center;
    }
    .contactNav {
        margin: 10px;
        align-items: center;
        text-align: center;
    }
    .dropdown{
        text-align: center;
    }

    .customBtn {
        background-color: #796234 !important;
        border: none;
        padding: 10px 10px;
        font-size: 18px;
        color: #01323b !important;
        font-weight: 500;
        border-radius: 5px;
        margin-bottom: 20px;
    }

    #customBtnLink {
        text-decoration: none !important;
        color: #01323b !important;
    }

    .vertical {
       display: none;
    }
}


@media (min-width:800px) and (max-width:991px){
    #basic-navbar-nav{
    background-color: #01323b;
    position: absolute;
    transform: translate(35%, 75%);
    transition: 1s all;
    animation-delay: 2s;
    border-radius: 5px;
    width: 400px;
    }  

    .homeNav {
        margin: 0px;
        align-items: center;
        text-align: center;
    }
    .aboutNav {
        margin: 10px;
        align-items: center;
        text-align: center;
    }
    .serviceNav {
        margin: 10px;
        align-items: center;
        text-align: center;
    }
    .contactNav {
        margin: 10px;
        align-items: center;
        text-align: center;
    }
    .dropdown{
        text-align: center;
    }

    .customBtn {
        background-color: #796234 !important;
        border: none;
        padding: 10px 10px;
        font-size: 18px;
        color: #01323b !important;
        font-weight: 500;
        border-radius: 5px;
        margin-bottom: 20px;
    }

    #customBtnLink {
        text-decoration: none !important;
        color: #01323b !important;
    }

    .vertical {
       display: none;
    }
}