@font-face {
    font-family: "Roboto-Regular";
     src: url('../../assets/Fonts/Roboto-Regular.ttf') format('opentype');
}

@font-face {
     font-family: "Roboto-Bold";
     src: url('../../assets/Fonts/Roboto-Bold.ttf') format('opentype');
}

@font-face {
    font-family: "Roboto-Medium";
     src: url('../../assets/Fonts/Roboto-Medium.ttf') format('opentype');
}
@font-face {
    font-family: "Roboto-Thin";
     src: url('../../assets/Fonts/Roboto-Thin.ttf') format('opentype');
}
@font-face {
    font-family: "Roboto-Black";
     src: url('../../assets/Fonts/Roboto-Black.ttf') format('opentype');
}
@font-face {
    font-family: "HindSiliguri-Bold";
     src: url('../../assets/Fonts/HindSiliguri/HindSiliguri-Bold.ttf') format('opentype');
}

.t-Heading{
    font-family: "Roboto-Bold";
    font-size: 16px;
    letter-spacing: 5px;
}

.leftHeading{
    font-family: "Roboto-Regular";
    font-weight: 900;
    letter-spacing: 1px;

}
.testCustomBtn {
    font-family: "HindSiliguri-Bold";
    background-color: #01323b;
    border: none;
    padding: 10px 8px;
    font-size: 16px;
    color: #796234;
    font-weight: 500;
    border-radius: 5px;
    margin-bottom: 20px;
}
.leftBox{
     padding: 40px 30px;
    box-shadow: 1px 8px 16px 13px #f1f1f1;
    border-radius: 20px;
}
.boxHeading{
    font-family: "Roboto-Bold";
    font-size: 20px;
    margin: 0px 15px 25px 0px;
}
.boxText{
    font-family: "Roboto-Regular";
    color: gray;
    word-spacing: 4px;
    font-size: 15px;

}
.rightBox{
    padding: 50px 50px;
    background-color: #01323b;
    border-radius: 20px;
    color: white;
    height: 500px;

}

.Comma{
    font-family: "Roboto-Black";
    float: right;
    font-size: 60px;
    color: lightgray;
}
.CommaRight{
    font-family: "Roboto-Black";
    float: right;
    font-size: 120px;
    color: #796234; 
}


.innerBox{
    padding: 26px 0px;

}
.innerText{
    word-spacing: 5px;
    font-size: 20px;
    font-family: "Roboto-Regular";
}

.lefBoxCard{
    display: flex;
    flex-direction: row;

    border-radius: 5px;
}
.leftBoxImage{
    width: 10%;
    border-radius: 10px;

}


.leftBoxText{
    font-family: "Roboto-Regular";
    font-size: 17px;
    margin-left: 10px;
    font-weight: 500;
}

/* Meida Query */

@media (min-width:200px) and (max-width:768px) {
    .innerText {
        word-spacing: 5px;
        font-size: 18px;
        font-family: "Roboto-Regular";
    }

    .leftHeading {
        font-family: "Roboto-Regular";
        font-weight: 900;
        letter-spacing: 1px;
        font-size: 18px;
    }

    .CommaRight {
        font-family: "Roboto-Black";
        float: right;
        font-size: 50px;
        color: #796234;
    }

    .rightBox {
        padding: 25px 25px;
        background-color: #01323b;
        border-radius: 20px;
        color: white;
        height: 100%;
        margin-top: 30px;
    }
}

@media (min-width:768px) and (max-width:992px) {
    .rightBox {
        padding: 25px 25px !important;
        background-color: #01323b;
        border-radius: 20px;
        color: white;
        height: 100%;
    }
}